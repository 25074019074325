import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'

// 获取最优惠会员卡
export function getMostDisMemberCard(params: object): Promise<any> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/membercards/prime/`,
    params
  })
}