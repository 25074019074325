
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Dialog } from 'element-ui'
import dsIcon from '@/modules/common/components/ds-icon.vue'
import { getH5Host } from '@/utils/utils'
// import { H5Host } from '@/config/index'
// const QRCode = require('@keeex/qrcodejs-kx')
const uuidv4 = require('uuid/v4')

@Component({
  components: {
    [Dialog.name]: Dialog,
    dsIcon
  }
})
export default class ComponentRightBar extends Vue {
  @Prop({ default: { showDialog: false, type: '', contentId: '', title: '' } }) option!: any
  qrcodeId: string = uuidv4()
  imglink: string = ''
  @Watch('imglink') onImglinkChanged(val: string, oldVal: string) {
    if (val) {
      this.$nextTick(() => {
        const QRCode = require('@keeex/qrcodejs-kx')
        let qrcode = new QRCode(this.qrcodeId, {
          width: 210,    // 设置宽度，单位像素
          height: 210,   // 设置高度，单位像素
          text: val      // 设置二维码内容或跳转地址
        });
      })
    }
  }
  created() {
  }
  // 匹配H5码路径
  getlink() {
    let link: string = '';
    const h5Host = getH5Host()
    if (this.option.type == 'course') {
      link = `${h5Host}/#/brief/course/${this.option.contentId}`
    } else if (this.option.type == 'memberCard' || this.option.type == 'member_card' || this.option.type == 'membercard') {
      link = `${h5Host}/#/card/${this.option.contentId}`
    } else if (this.option.type == 'home') {
      link = `${h5Host}`
    } else if (this.option.type == 'offlinecourse') {
      link = `${h5Host}/#/offline/detail/${this.option.contentId}`
    } else if (this.option.type == 'content_category') {
      link = `${h5Host}/#/classify/transfer?id=${this.option.contentId}`
    } else if (this.option.type == 'community') {
      link = `${h5Host}/#/community/detail/${this.option.contentId}`
    } else if (this.option.type == 'coupon') {
      link = `${h5Host}/#/coupon/detail/${this.option.contentId}`
    } else if (this.option.type == 'gift_code') {
      link = `${h5Host}/#/giftcode/${this.option.contentId}`
    } else if (this.option.type == 'purchase') {
      link = `${h5Host}/#/promotionlist/${this.option.contentId}`
    } else if (this.option.type == 'pintuan') {
      link = `${h5Host}`
    } else if (this.option.type == 'card') {
      link = `${h5Host}/#/taskcard/detail/${this.option.contentId}`
    } else if (this.option.type == 'consult') {
      link = `${h5Host}/#/consult/${this.option.contentId}`
    } else if (this.option.type == 'aimicrolecture') {
      link = `${h5Host}/#/aicourse/detail/${this.option.contentId}`
    } else if (this.option.type == 'entity') {
      link = `${h5Host}/#/commodity/detail/${this.option.contentId}`
    } else if (this.option.type == 'trainingcampclass') {
      link = `${h5Host}/#/camp/${this.option.contentId}`
    } else if (this.option.type == 'provingground') {
      link = `${h5Host}/#/training/detail/${this.option.contentId}`
    } else if (this.option.type == 'smallclass') {
      link = `${h5Host}/#/smallclass/detail/${this.option.contentId}`
    } else if (this.option.type == 'repeatedPractice') {
      link = `${h5Host}/#/dispel/detail/${this.option.contentId}`
    } else {
      link = `${h5Host}/#/brief/${this.option.type}/${this.option.contentId}`;
    }
    this.imglink = link;
  }
  dialogOpen() {
    this.getlink()
    console.log(this.imglink)
  }
}
