import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'

// 获取可用优惠券
export function getProductCoupon(params: object): Promise<model.ProductCouponList> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/coupon/product_coupon/`,
    params
  })
}

// 获取可领取优惠券
export function getGoodsCouponList(params: object): Promise<model.ProductCouponList> {
  return pyApi({
    method: 'GET',
    url: '/fairy/api/v1/coupon_activity/',
    params
  })
}