







import { Component, Vue, Prop } from 'vue-property-decorator'
import { time_utils } from '@/utils/time_utils'
import { PintuanDetail } from '@/modules/common/models/index.ts'
import { ContentInfo } from '@/modules/content/models/index.ts'
import { CourseInfo } from '@/modules/course/models'

@Component({
})
export default class ActivityBar extends Vue {
  @Prop({ default: () => { return {} } }) contentInfo!: CourseInfo | ContentInfo
  @Prop({ default: () => { return {} } }) pintuanDetail!: PintuanDetail
  @Prop({ default: 'pintuan' }) type!: string
  @Prop({ default: false }) mini!: boolean
  activityTimer: any = {
    day: '00',
    hour: '00',
    min: '00',
    sec: '00'
  }
  activityCountDown: any = null
  status: number = 0

  get typeText() {
    if (this.type == 'pintuan') {
      return `${this.mini ? '' : '拼团 · '}${this.pintuanDetail.people_number}人团`
    } else if (this.type == 'limit') {
      // return `${this.mini ? '' : '限时购 · '}${discount.toFixed(1)}折`
      return '限时价'
    }
  }

  get timerText() {
    let {day, hour, min, sec} = this.activityTimer
    let t = `${day}天${hour}:${min}:${sec}`
    let s = this.status ? '结束' : '开始'
    if (this.type === 'limit' && !s) {
      return `还有${t}开始`
    } else {
      return `距${s} ${t}`
    }
  }

  mounted() {
    this.doCountdown()
  }

  formatDate(s: string) {
    return s.replace(/-/g, '/')
  }

  doCountdown() {
    let startTime = 0
    let endTime = 0
    let nowTime = new Date().getTime()
    if (this.type === 'pintuan') {
      startTime = this.pintuanDetail.start_time * 1000
      endTime = this.pintuanDetail.end_time * 1000
    } else if (this.contentInfo.limit_purshase) {
      startTime = +new Date(this.formatDate(this.contentInfo.limit_purshase.start_time))
      endTime = +new Date(this.formatDate(this.contentInfo.limit_purshase.end_time))
    } else {
      startTime = +new Date(this.formatDate(this.contentInfo.limit_start))
      endTime = +new Date(this.formatDate(this.contentInfo.limit_end))
    }

    if (nowTime - endTime > 0) {
      this.status = -1
      return
    }
    // 未开始
    if (nowTime - startTime < 0) {
      this.status = 0
      this.activityCountDown = setInterval(() => {
        nowTime = new Date().getTime()
        if (nowTime - startTime < 0) {
          this.activityTimer = time_utils.counterTime(startTime)
        } else {
          this.status = 1
          this.doCountdown()
        }
      }, 1000)
    } else if (nowTime - endTime <= 0) {  // 进行中
      this.status = 1
      this.activityCountDown = setInterval(() => {
        nowTime = new Date().getTime()
        if (nowTime - endTime < 0) {
          this.activityTimer = time_utils.counterTime(endTime)
        } else {
          this.status = -1
          clearInterval(this.activityCountDown)
        }
      }, 1000)
    }
  }
}
