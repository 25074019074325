

















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  filters: {
    days(day:any) {
      if (!day) return ''
      if (isNaN(day)) {
        return day
      } else if (!(day % 365)) {
        return day / 365 + '年'
      } else if (!(day % 30)) {
        return day / 30 + '个月'
      } else {
        return day + '天'
      }
    }
  },
  components: {
  }
})
export default class ContentCard extends Vue {
  @Prop({ default: {} }) data!: any
  @Prop({ default: undefined }) fightGroup!: any
  @Prop({ default: undefined }) scoreInfo!: any
  @Prop({ default: true }) showLeaderPrice!: Boolean
  @Prop({ default: '' }) type!: any
  @Prop() limitInfo!: any

  activeIndex:any =  0
  columnValue:any = ''

  get skus() {
    let data = this.fightGroup || this.data
    if (data.related_skus) { // 拼团规格
      return data.related_skus
    } else if (this.isScore) {
      console.log('this.scoreInfo', this.scoreInfo)
      return this.scoreInfo.relateSkus || []
    } else if (this.isInLimitActivity && this.limitInfo.skus && this.limitInfo.skus.length) {
      let limitSkus = []
      for (let i = 0; i < this.skusList.length; i++) {
        let item = this.skusList[i]
        let limitItem = this.limitSkusItems[item.id]
        if (limitItem) {
          limitSkus.push({
            unit_price: (limitItem.discounted_price / 100).toFixed(2),
            original_price: (limitItem.unit_price / 100).toFixed(2),
            id: item.id
          })
        } else {
          limitSkus.push(item)
        }
      }
      return limitSkus
    } else if (this.skusList.length) {
      return this.skusList
    } else {
      return []
    }
  }
  get skusList() {
    // eslint-disable-next-line no-mixed-operators
    return this.data.sku_info && this.data.sku_info.skus || []
  }
  get limitSkusItems() {
    let skus = this.limitInfo.skus
    if (!skus) return []
    let items:any = {}
    for (let i = 0; i < skus.length; i++) {
      items[skus[i].sku_id] = skus[i]
    }
    return items
  }
  get specDays() {
    let dayArr = []
    let skus = this.skusList
    if (skus.length) {
      for (let i in skus) {
        dayArr.push(skus[i].specs && skus[i].specs[0])
      }
    }
    return dayArr
  }
  get isScore() {
    return !!this.scoreInfo
  }
  get isMemberFree() {
    return +this.data.cost_price > 0 && !(+this.data.price)
  }
  get isInLimitActivity() {
    return this.limitInfo.status === 'started'
  }
  changeIndex(index:any) {
    this.activeIndex = index
  }
  doSubmit() {
    let index = this.activeIndex
    this.$emit('submit', this.skus[index])
  }
  price(item:any, index:any) {
    if (!this.isScore) {
      let p = ''
      // 团长优惠
      if (item.group_leader_price && this.showLeaderPrice && this.fightGroup.enabled_group_leader_discount) {
        p = (item.group_leader_price / 100).toFixed(2)
      } else if (item.discount_price) { // 拼团价
        p = (item.discount_price / 100).toFixed(2)
      } else {
        if (this.type === 'present' || this.isMemberFree) { // 购买赠送显示原价
          p = item.cost_price || item.unit_price
        } else {
          p = item.unit_price
        }
      }
      return p
    } else {
      if (!this.scoreInfo.relateSkus) return ''
      return this.getScorePrice(this.scoreInfo.relateSkus[index])
    }
  }
  oldPrice(item:any) {
    if (this.type === 'present' || this.isMemberFree) {
      return item.original_price
    } else {
      return (item.origin_price / 100) || item.cost_price || item.original_price
    }
  }
  getScorePrice(item:any) {
    let price = item.unitPrice
    let score = item.scorePrice
    let text = score + '积分'
    if (price) {
      text += `+${(price / 100).toFixed(2)}元`
    }
    return text
  }
  columnDays(day:any) {
    if (!day) return ''
    if (isNaN(day)) {
      return day
    } else if (!(day % 365)) {
      return day / 365 + '年'
    } else if (!(day % 30)) {
      return day / 30 + '个月'
    } else {
      return day + '天'
    }
  }

}
