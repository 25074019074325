









import { Component, Vue, Prop } from 'vue-property-decorator'
import { getMostDisMemberCard } from '@/modules/membercard/api/index.ts'
import { DiscountMembercard } from '@/modules/membercard/models/index.ts'
import { ContentInfo } from '@/modules/content/models/index.ts'
import { getGoodsCouponList } from '@/modules/coupon/api/index.ts'
import { ProductCouponList } from '@/modules/coupon/models/index.ts'
import sweepCodeDialog from '@/modules/common/components/sweep-code-dialog.vue'
import { checkLogin } from '@/utils/user'

@Component({
  components: {
    sweepCodeDialog
  }
})
export default class discountInfo extends Vue {
  @Prop({ default: {} }) contentInfo!: ContentInfo

  disMemberCard: DiscountMembercard = {}
  couponList: any = []
  dialogOption: object = {
    showDialog: false,
    type: '',
    contentId: '',
    title: '更多精彩内容请访问移动端店铺'
  }

  mounted() {
    this.getDisMemberCard()
    this.getProductCouponList()
  }

  get memberCardText() {
    if (this.disMemberCard.discount === -1) {
      return '会员卡免费'
    } else if (this.disMemberCard.discount) {
      return `会员卡折扣${this.disMemberCard.discount}折`
    }
  }

  // 获取最优惠会员卡
  getDisMemberCard() {
    let id = this.contentInfo.content_id || this.contentInfo.id
    let type = this.contentInfo.type
    let returnTypes = ['offlinecourse']
    if (!checkLogin() || !id || returnTypes.includes(type)) return
    getMostDisMemberCard({
      content_id: id,
      content_type: type
    }).then(res => {
      this.disMemberCard = res
    })
  }

  // 获取可用优惠券
  getProductCouponList() {
    if (!checkLogin()) return
    let id = this.contentInfo.content_id || this.contentInfo.id
    getGoodsCouponList({
      product_id: id,
      type: this.contentInfo.type
    }).then(res => {
      this.couponList = res
    })
  }

  showMemberCardDialog() {
    let data = this.disMemberCard
    this.dialogOption = {
      showDialog: true,
      type: 'membercard',
      contentId: data.id,
      title: '更多精彩内容请访问移动端店铺'
    }
  }

  couponListToggle() {
    this.$emit('couponListToggle')
  }
}
