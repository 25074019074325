


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import gAudio from '@/modules/common/components/g-audio.vue';
import { ContentInfo } from '@/modules/content/models/index.ts'
import { PintuanDetail } from '@/modules/common/models/index.ts'

@Component({
  components: {
    gAudio
  }
})
export default class ComponentTopAudio extends Vue {
  @Prop() coverImgUrl?: string
  @Prop() url?: string
  @Prop({ default: true }) isSetTop?: boolean
  @Prop({ default: false }) isFree?: boolean
  @Prop({ default: () => { return {} } }) pintuanDetail!: PintuanDetail
  @Prop({ default: () => { return {} } }) contentInfo!: ContentInfo
  @Watch('isSetTop', { immediate: true })
  onIsSetTopChanged() {
    let aAudio: any = this.$refs.gAudio
    if (aAudio) {
      this.$nextTick(() => {
        aAudio.updateDot()
      })
    }
  }

  get isInPintuanActivity() {
    let data = this.pintuanDetail
    let n = +new Date()
    let s = data.start_time * 1000
    let e = data.end_time * 1000
    return n >= s && n < e && data.activation
  }
  get isInLimitActivity() {
    let data = this.contentInfo
    let n = +new Date()
    let s = +new Date(data.limit_start)
    let e = +new Date(data.limit_end)
    return n >= s && n < e && data.limit_price
  }
  get curPrice() {
    let price = ''
    if (this.isInPintuanActivity) {
      price = `￥${(this.pintuanDetail.related_skus[0].discount_price / 100).toFixed(2)}开团`
    } else if (this.isInLimitActivity) {
      price = `限时￥${this.contentInfo.limit_price}`
    } else {
      price = `￥${this.contentInfo.price}购买`
    }
    return price
  }
  get originPrice() {
    let price: string | undefined = ''
    if (this.isInPintuanActivity) {
      price = (this.pintuanDetail.related_skus[0].origin_price / 100).toFixed(2)
    } else if (this.isInLimitActivity) {
      price = this.contentInfo.cost_price
    } else {
      price = this.contentInfo.original_price
    }
    return price
  }

  toBuy() {
    this.$emit('toBuy')
  }
  play() {
    let gAudio: any = this.$refs.gAudio
    gAudio.play()
  }
  changeSpeed(times: number) {
    (this.$refs.gAudio as gAudio).changeSpeed(times)
  }
}
